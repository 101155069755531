@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
html {
    @apply font-open-sans;
}
}

.dots-9 {
    width: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    clip-path: inset(-45px);
    box-shadow: -60px 15px #ec4842,-60px 15px #ec4842,-60px 15px #ec4842;
    transform: translateY(-15px);
    animation: d9 1s infinite linear;
  }
  
  @keyframes d9{ 
    16.67% {box-shadow:-60px 15px #ec4842,-60px 15px #ec4842,19px 15px #ec4842}
    33.33% {box-shadow:-60px 15px #ec4842,  0px 15px #ec4842,19px 15px #ec4842}
    40%,60%{box-shadow:-19px 15px #ec4842,  0px 15px #ec4842,19px 15px #ec4842}
    66.67% {box-shadow:-19px 15px #ec4842,  0px 15px #ec4842,60px 15px #ec4842}
    83.33% {box-shadow:-19px 15px #ec4842, 60px 15px #ec4842,60px 15px #ec4842}
    100%   {box-shadow: 60px 15px #ec4842, 60px 15px #ec4842,60px 15px #ec4842}
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
